<template ref="adminUser">
  <div>
      <header-admin-pages
        title="Пользователи"
        :counter="this.$store.getters.getUsersCounter"
        :button="{ title: 'Новый пользователь', link: '/admin/users/create_user?type=create' }"
      />
      <form @reset="formReset" @submit.prevent="filterSubmit" class="filters">
        <input  v-model="filers.email"  placeholder="email" type="email" name="email" class="input blue">

        <input  v-model="filers.name"  placeholder="ФИО" type="text" name="fio" class="input blue">

        <select   class="input blue" name="role" @change="changeRole" placeholder="test">
          <option disabled selected>Выберите Роль</option>
          <option v-for="item in getRole" :key="`getRole${item.id}`" :value="item.code">{{ item.title }}</option>
        </select>

        <select   class="input blue" @change="changeStatus" name="status">
          <option disabled selected>Выберите Статус</option>
          <option v-for="item in getStatus" :key="`getStatus${item.id}`" :value="item.code">{{ item.title }}</option>
        </select>

        <div>
          <input  class="filters-reset" type="reset" value="Сбросить фильтры">
          <input class="btn-blue" type="submit" value="Найти">
        </div>

      </form>
      <main>
        <table>
          <tr>
            <td><p>Фио</p></td>
            <td><p>Email</p></td>
            <td><p>Дата</p></td>
            <td><p>Роль</p></td>
            <td><p>Статус</p></td>
            <td></td>
          </tr>
          <tr v-for="item in getUsers" :key="`user${item.id}`">
            <td><p>{{ item.fullName }}</p></td>
            <td><a :href="`mailto:${item.email}`">{{ item.email }}</a></td>
            <td><p>{{ item.createDate }}</p></td>
            <td><p>{{ item.status }}</p></td>
            <td><p>{{ item.role }}</p></td>
            <td>
              <drop-list @clickItem="clickItem" :idItem="item.id"/>
            </td>
          </tr>
        </table>
        <pagination/>
      </main>
  </div>
</template>

<script>
import HeaderAdminPages from '@/components/Admin/HeaderAdminPages'
import Pagination from '@/components/Pagination'
import DropList from '@/components/Admin/DropList'
export default {
  name: 'Users',
  data () {
    return {
      list: [
        {
          id: 1,
          code: 'PRESIDENT',
          title: 'Актуальный комментарий'
        }
      ],
      filers: {
        email: '',
        name: '',
        role: '',
        status: ''
      },
      convocationList: []
    }
  },
  computed: {
    getUsers () {
      return this.$store.getters.getUsers
    },
    getRole () {
      return this.$store.getters.getRole
    },
    getStatus () {
      return this.$store.getters.getStatus
    }
  },
  methods: {
    clickItem (value) {
      if (value.value === 'del') {
        const consent = confirm('Вы уверены, чтобы хотите удалить пользователя ?')
        if (consent) {
          this.$store.dispatch('deleteUser', value.id)
        } else {
          this.$noty.warning('Удаление отменено')
        }
      } else {
        this.$store.dispatch('setDetailUser', value.id)
      }
    },
    filterSubmit () {
      this.$store.dispatch('filterUser', this.filers)
    },
    changeRole (e) {
      this.filers.role = e.target.value
    },
    changeStatus (e) {
      this.filers.status = e.target.value
    },
    formReset () {
      this.$store.dispatch('setUserAll', this.$store.getters.getUrlApi)
      this.filers.name = ''
      this.filers.email = ''
    }
  },
  mounted () {
    this.$store.dispatch('setUserAll')
    this.$store.dispatch('setRole')
    this.$store.dispatch('setStatus')
    this.$store.dispatch('setMemberId')
  },
  components: {
    HeaderAdminPages,
    Pagination,
    DropList
  }
}
</script>

<style scoped lang="scss">
.filters{
  margin-top: 1.37rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: 1px solid #D5D6D9;
}
.input{
  width: 32%;
  margin-bottom: 1.25rem;
}
.filters-reset {
  background: none;
  font-size: 0.75rem;
  color: #003E78;
  opacity: 0.6;
  margin-right: 1.37rem;
  cursor: pointer;
}
table{
  margin-top: 2rem;
  border-spacing: 0;
  border-collapse: collapse;

  tr{
    padding: 0;
    border-bottom: 1px solid #D5D6D9;
  }
}
td {
  max-width: 23%;
}
tr {
  p {
    font-size: 0.812rem;
    line-height: 154%;
    color: #5E686C;
  }
}
tr {
  a {
    font-size: 0.812rem;
    line-height: 154%;
    color: #246CB7;
  }
}

</style>
